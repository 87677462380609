import {
  Checkbox,
  Collapse,
  IconButton,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import AddIcon from "@material-ui/icons/Add";
import PaletteIcon from "@material-ui/icons/Palette";
import PaletteOutlinedIcon from "@material-ui/icons/PaletteOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { ColorPicker } from "material-ui-color";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { FeatureType, FeatureTypeList, LayerType, LayerTypeList } from "./TransferLayerClasses";
import { useFeatureTypeStyles } from "./TransferLayerStyles";
import { toJS } from "mobx";
import { InjectedIntl, injectIntl } from "react-intl";
import messages from "./messages";
import DeleteIcon from "@mui/icons-material/Delete";

export const FeatureTypeListView: React.FC<{ featureTypeList: FeatureTypeList; intl: InjectedIntl }> = observer(
  ({ featureTypeList, intl: { formatMessage }, intl }: { featureTypeList: FeatureTypeList; intl: InjectedIntl }) => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox checked={featureTypeList.allEnabled} onClick={() => featureTypeList.toggleAll()} />
            </TableCell>
            <TableCell colSpan={2}>{formatMessage(messages.transferlayerFeatureType)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {featureTypeList.featureTypes.map((featureType, index) => (
            <FeatureTypeView featureType={featureType} key={index} intl={intl} />
          ))}
        </TableBody>
      </Table>
    );
  },
);

injectIntl(FeatureTypeListView);

export const FeatureTypeView: React.FC<{ featureType: FeatureType; key; intl: InjectedIntl }> = observer(
  ({ featureType, key, intl: { formatMessage } }: { featureType: FeatureType; key; intl: InjectedIntl }) => {
    const classes = useFeatureTypeStyles();
    const type = featureType.featureType.Type;
    const properties = featureType.featureType.Properties;
    const comparisonOperator = featureType.featureType.ComparisonOperator;
    const colSpan = type === "Polygon" || type === "MultiPolygon" ? 3 : 2;

    const translateOperator = (operator) => {
      switch (operator) {
        case "LessThan":
        case "PropertyIsLessThan":
          return formatMessage(messages.transferlayerLessThan);
        case "GreaterThan":
        case "PropertyIsGreaterThan":
          return formatMessage(messages.transferlayerMoreThan);
        case "LessThanEqualTo":
        case "PropertyIsLessThanOrEqualTo":
          return formatMessage(messages.transferlayerLessAndEqualTo);
        case "GreaterThanEqualTo":
        case "PropertyIsGreaterThanOrEqualTo":
          return formatMessage(messages.transferlayerMoreAndEqualTo);
        case "EqualTo":
        case "PropertyIsEqualTo":
          return formatMessage(messages.transferlayerEqualTo);
        case "NotEqualTo":
        case "PropertyIsNotEqualTo":
          return formatMessage(messages.transferlayerNotEqualTo);
        case "Like":
        case "PropertyIsLike":
          return formatMessage(messages.transferlayerLike);
        case "Between":
        case "PropertyIsBetween":
          return formatMessage(messages.transferlayerBetween);
        case "NullCheck":
        case "PropertyIsNull":
          return formatMessage(messages.transferlayerNotNull);
        default:
          return null;
      }
    };

    return (
      <Fragment key={key}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox checked={featureType.enabled} onClick={() => featureType.toggleEnabled()} />
          </TableCell>
          <TableCell>
            <Box style={{ display: "flex" }}>
              {featureType.featureType.Title}
              {featureType.featureType.Abstract && (
                <Tooltip title={featureType.featureType.Abstract}>
                  <HelpIcon className={classes.abstractHelp} cursor="help" />
                </Tooltip>
              )}
            </Box>
            <Typography style={{ fontSize: "0.8rem" }}>{featureType.featureType.Name} </Typography>{" "}
          </TableCell>
          <TableCell padding="checkbox">
            <IconButton size="small" onClick={() => featureType.toggleEditStyle()}>
              {featureType.editStyle ? <PaletteIcon /> : <PaletteOutlinedIcon />}
            </IconButton>
            <IconButton size="small" onClick={() => featureType.toggleEditFilter()}>
              {featureType.editFilter ? <FilterListIcon /> : <FilterListOutlinedIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} className={classes.styleTable}>
            <Collapse in={featureType.editStyle} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Typography variant="caption">{formatMessage(messages.transferlayerStyleGeographicType, { type })}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {(type === "Polygon" || type === "MultiPolygon") && (
                      <TableCell>
                        <Typography variant="caption">{formatMessage(messages.transferlayerBorderColor)}</Typography>
                        <div className={classes.test}>
                          <ColorPicker value={featureType.borderColor} onChange={(color) => featureType.setBorderColor(color)} />
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <Typography variant="caption">{formatMessage(messages.transferlayerFillColor)}</Typography>
                      <div className={classes.test}>
                        <ColorPicker value={featureType.fillColor} onChange={(color) => featureType.setFillColor(color)} />
                      </div>
                    </TableCell>
                    {(type === "Polygon" || type === "MultiPolygon" || type === "LineString" || type === "MultiLineString") && (
                      <TableCell>
                        <Typography variant="caption">{formatMessage(messages.transferlayerTransparency)}</Typography>
                        <Slider value={featureType.opacity} valueLabelDisplay="auto" onChange={(event, newValue) => featureType.setOpacity(newValue)} />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
            <Collapse in={featureType.editFilter} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Typography variant="caption">{formatMessage(messages.transferlayerFiltersProperty)}</Typography>
                    </TableCell>
                  </TableRow>
                  {featureType?.filterList?.filters?.map?.((filter, filterIndex) => (
                    <TableRow>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "space-between",
                        }}
                        key={"filterIndex"}
                      >
                        <TableCell style={{ flexGrow: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel id="eigenschap-label">{formatMessage(messages.transferlayerPropertyDutch)}</InputLabel>
                            <Select
                              labelId="eigenschap-label"
                              id="eigenschap"
                              label={formatMessage(messages.transferlayerPropertyDutch)}
                              value={filter.property}
                              onChange={(e) => filter.setProperty(e.target.value)}
                            >
                              {properties?.map?.((property, index) => (
                                <MenuItem key={index} value={property}>
                                  {property}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell style={{ flexGrow: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel id="operator-label">{formatMessage(messages.transferlayerOperator)}</InputLabel>
                            <Select
                              labelId="operator-label"
                              id="operator"
                              label={formatMessage(messages.transferlayerOperator)}
                              value={filter.operator}
                              onChange={(e) => filter?.setOperator?.(e.target.value)}
                            >
                              {comparisonOperator.map((operator, index) => {
                                const label = translateOperator(operator);
                                if (!label) return null;
                                return (
                                  <MenuItem key={index} value={operator}>
                                    {label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                        {!["NullCheck", "PropertyIsNull"].includes(filter.operator) && (
                          <TableCell style={{ flexGrow: 1 }}>
                            <TextField
                              id="eigenschapsvalue"
                              label={formatMessage(messages.transferlayerValue1)}
                              variant="standard"
                              value={filter.value1}
                              onChange={(e) => filter.setValue1(e.target.value)}
                            />
                          </TableCell>
                        )}
                        {["Between", "PropertyIsBetween"].includes(filter.operator) && (
                          <Box style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                            <TableCell>en</TableCell>
                          </Box>
                        )}
                        {["Between", "PropertyIsBetween"].includes(filter.operator) && (
                          <Box style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                            <TableCell>
                              <TextField
                                id="eigenschapsvalue2"
                                label={formatMessage(messages.transferlayerValue2)}
                                variant="standard"
                                value={filter.value2}
                                onChange={(e) => filter.setValue2(e.target.value)}
                              />
                            </TableCell>
                          </Box>
                        )}
                        <Box style={{ flexGrow: 0 }}>
                          <IconButton size="small">
                            <DeleteIcon onClick={() => featureType.filterList.removeFilter(filterIndex)} />
                          </IconButton>
                        </Box>
                      </Box>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={colSpan}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => featureType.filterList.addFilter(properties[0], comparisonOperator[0])}
                      >
                        {formatMessage(messages.transferlayerAddFilter)}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel id="eigenschap-label">Eigenschap</InputLabel>
                      <Select labelId="eigenschap-label" id="eigenschap" label="Eigenschap">
                        {properties.map((property, index) => (
                          <MenuItem key={index} value={property}>
                            {property}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel id="operator-label">Operator</InputLabel>
                      <Select labelId="operator-label" id="operator" label="Operator">
                        <MenuItem value={">"}>is groter dan</MenuItem>
                        <MenuItem value={"<"}>is kleiner dan</MenuItem>
                        <MenuItem value={"="}>is gelijk aan</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField id="eigenschapsvalue" label="Waarde" variant="standard" />
                  </TableCell>
                </TableRow> */}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  },
);

injectIntl(FeatureTypeView);

export const LayerTypeListView = observer(({ layerTypeList, intl: { formatMessage } }: { layerTypeList: LayerTypeList; intl: InjectedIntl }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={layerTypeList.allEnabled} onClick={() => layerTypeList.toggleAll()} />
        </TableCell>
        <TableCell colSpan={2}>{formatMessage(messages.transferlayerLayers)}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {layerTypeList.layerTypes.map((layerType, index) => (
        <LayerTypeView layerType={layerType} key={index} />
      ))}
    </TableBody>
  </Table>
));

injectIntl(LayerTypeListView);

export const LayerTypeView: React.FC<{ layerType: LayerType; key }> = observer(({ layerType, key }) => {
  const classes = useFeatureTypeStyles();
  return (
    <Fragment key={key}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={layerType.enabled} onClick={() => layerType.toggleEnabled()} />
        </TableCell>
        <TableCell>
          <Box style={{ display: "flex" }}>
            {layerType.layerType.Title}
            {layerType.layerType.Abstract && (
              <Tooltip title={layerType.layerType.Abstract}>
                <HelpIcon className={classes.abstractHelp} cursor="help" />
              </Tooltip>
            )}{" "}
          </Box>
          <Typography style={{ fontSize: "0.8rem" }}>{layerType.layerType.Name} </Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  );
});
